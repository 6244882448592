import React from "react"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { FaCheckCircle as CheckIcon } from "react-icons/fa"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./pilot.scss"
import FormsService from "../service/formsservice"

export default class Pilot extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      submitting: false,
      formData: {},
      otherTextFlag: false,
    }
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    this.setState((oldState) => {
      return {
        ...oldState,
        formData: {
          ...oldState.formData,
          [name]: value,
        },
      }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    this.setState({ submitting: true })
    let data = JSON.stringify(this.state.formData)
    FormsService.pilotForm(data)
      .then((res) => {
        if (res) {
          this.setState({ submitted: true })
        }
      })
      .catch((error) => console.log("Error: ", error))
      .finally(() => {
        this.setState({ submitting: false })
      })
  }

  render() {
    const { data } = this.props
    const { submitted, submitting } = this.state

    return (
      <Layout>
        <SEO title="Pilot" />
        <div className="outer-wrapperPilot">
          <Container className="containerPilot">
            <div className="inner-wrapperPilot">
              <div className="desc-parentPilot">
                <h1>Request a Pilot</h1>
                <p>
                  This questionnaire helps us understand your requirements for
                  piloting SHAPE and rapidly set up your account. It includes
                  details of the SSA, who is the SHAPE Champion and will be
                  operating the pilot.
                </p>
              </div>
              <form
                className="form-parentPilot"
                name="pilot"
                action="/thanks/"
                onSubmit={this.handleSubmit}
                disabled={submitted}
                id="pilot-form"
              >
                <Row>
                  <Col sm={12}>
                    <div className="input-parent">
                      <label>Your full name</label>
                      <input
                        type="text"
                        required
                        id="pilot-input-name"
                        name="fullName"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="input-parent">
                      <label>Your company name</label>
                      <input
                        type="text"
                        required
                        id="pilot-input-company"
                        name="companyName"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="input-parent">
                      <label>Your email address</label>
                      <input
                        type="text"
                        required
                        id="pilot-input-email"
                        name="email"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="input-parent">
                      <label>Your contact number</label>
                      <input
                        type="text"
                        required
                        id="pilot-input-contact"
                        name="contactNumber"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="input-parent">
                      <label className="customHeight">
                        Your office address
                        <br />
                        <span>
                          We require this to confirm your agreement to the{" "}
                          <a href="/pilotterms" target="_blank">
                            Pilot Terms of Use
                          </a>
                          .
                        </span>
                      </label>
                      <input
                        type="text"
                        required
                        id="pilot-input-officeaddress"
                        name="address"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="input-parent">
                      <label>Total number of people in your organisation</label>
                      <input
                        type="text"
                        required
                        id="pilot-input-noofpeople"
                        name="numberOfPeople"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="input-parent">
                      <label className="customHeight">
                        The survey period will be 8 working days. Please confirm
                        if this duration is sufficient for your organisation.
                        <br />
                        <span>
                          The survey period refers to the time from the start
                          date of the survey till the survey deadline.
                        </span>
                      </label>
                      <div>
                        <label className="form-group">
                          <input
                            className="radio-input"
                            type="radio"
                            value="Yes"
                            id="pilot-input-surveyperiod-yes"
                            required
                            name="sufficientSurveyDuration"
                            onChange={this.handleChange}
                          />{" "}
                          Yes
                        </label>
                        <br />
                        <label className="form-group">
                          <input
                            className="radio-input"
                            type="radio"
                            value="No"
                            id="pilot-input-surveyperiod-no"
                            required
                            name="sufficientSurveyDuration"
                            onChange={this.handleChange}
                          />{" "}
                          No
                        </label>
                      </div>
                    </div>

                    <div className="input-parent">
                      <label className="customHeight">
                        Email of the person to be nominated as SSA
                        <br />
                        <span>
                          The SSA, or SHAPE Senior Administrator, is the SHAPE
                          Champion in your organisation. This is the person who
                          will work with us to set up SHAPE for your company and
                          run the coordination with us. This may be you, or a
                          colleague.
                        </span>
                      </label>
                      <input
                        type="text"
                        required
                        id="pilot-input-ssaemail"
                        name="ssaEmail"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="input-parent">
                      <label className="customHeight">
                        Are your employees able to complete the survey in
                        English? <br />
                      </label>
                      <div>
                        <label className="form-group">
                          <input
                            type="radio"
                            value="Yes"
                            id="pilot-input-englishsurvey-yes"
                            required
                            className="radio-input"
                            name="comfortableEnglishSurvey"
                            onChange={this.handleChange}
                          />{" "}
                          Yes
                        </label>
                        <br />
                        <label className="form-group">
                          <input
                            type="radio"
                            required
                            className="radio-input"
                            id="pilot-input-englishsurvey-no"
                            value="No"
                            name="comfortableEnglishSurvey"
                            onChange={this.handleChange}
                          />{" "}
                          No
                        </label>
                        <br />
                        <label className="form-group">
                          <input
                            type="radio"
                            value="Maybe"
                            id="pilot-input-englishsurvey-maybe"
                            required
                            className="radio-input"
                            name="comfortableEnglishSurvey"
                            onChange={this.handleChange}
                          />{" "}
                          Maybe
                        </label>
                      </div>
                    </div>

                    <div className="input-parent">
                      <label className="customHeight">
                        You will need to nominate a management figurehead (e.g.,
                        CEO). Do you have such a figurehead for pilot purposes?
                      </label>
                      <div>
                        <label className="form-group">
                          <input
                            type="radio"
                            className="radio-input"
                            id="pilot-input-figurehead-yes"
                            name="haveManagementFigureHead"
                            required
                            value="Yes"
                            onChange={this.handleChange}
                          />{" "}
                          Yes
                        </label>
                        <br />
                        <label className="form-group">
                          <input
                            type="radio"
                            className="radio-input"
                            value="No"
                            required
                            id="pilot-input-figurehead-no"
                            name="haveManagementFigureHead"
                            onChange={this.handleChange}
                          />{" "}
                          No
                        </label>
                      </div>
                    </div>

                    {/* addintional question */}
                    <div className="input-parent">
                      <label className="customHeight">
                        Please select the current employee feedback mechanism
                        you have in place at your organisation.
                      </label>
                      <div>
                        <label className="form-group">
                          <input
                            className="radio-input"
                            type="radio"
                            value="In-house survey"
                            id="pilot-input-feedback-inhouse"
                            required
                            name="currentFeedbackMechanism"
                            onChange={this.handleChange}
                            onClick={() =>
                              this.setState({ otherTextFlag: false })
                            }
                          />{" "}
                          In-house survey
                        </label>
                        <br />
                        <label className="form-group">
                          <input
                            className="radio-input"
                            type="radio"
                            value="External (third-party) supplier"
                            required
                            id="pilot-input-feedback-external"
                            name="currentFeedbackMechanism"
                            onChange={this.handleChange}
                            onClick={() =>
                              this.setState({ otherTextFlag: false })
                            }
                          />{" "}
                          External (third-party) supplier
                        </label>
                        <br />

                        <label className="form-group">
                          <input
                            className="radio-input"
                            type="radio"
                            value="Combination of internal and external"
                            required
                            id="pilot-input-feedback-both"
                            name="currentFeedbackMechanism"
                            onChange={this.handleChange}
                            onClick={() =>
                              this.setState({ otherTextFlag: false })
                            }
                          />{" "}
                          Combination of internal and external
                        </label>
                        <br />

                        <label className="form-group">
                          <input
                            className="radio-input"
                            type="radio"
                            value="None"
                            required
                            id="pilot-input-feedback-none"
                            name="currentFeedbackMechanism"
                            onChange={this.handleChange}
                            onClick={() =>
                              this.setState({ otherTextFlag: false })
                            }
                          />{" "}
                          None
                        </label>
                        <br />

                        <label className="form-group">
                          <input
                            className="radio-input"
                            type="radio"
                            value="Don’t know"
                            required
                            id="pilot-input-feedback-dontknow"
                            name="currentFeedbackMechanism"
                            onChange={this.handleChange}
                            onClick={() =>
                              this.setState({ otherTextFlag: false })
                            }
                          />{" "}
                          Don’t know
                        </label>
                        <br />

                        <label className="form-group">
                          <input
                            className="radio-input"
                            type="radio"
                            value="Other"
                            required
                            id="pilot-input-feedback-other"
                            name="currentFeedbackMechanism"
                            onClick={() =>
                              this.setState({ otherTextFlag: true })
                            }
                          />{" "}
                          Other
                        </label>
                        {this.state.otherTextFlag && (
                          <input
                            type="text"
                            required
                            id="pilot-input-feedback-otherinput"
                            name="currentFeedbackMechanism"
                            className="form-control"
                            onChange={this.handleChange}
                          />
                        )}
                      </div>
                    </div>

                    <div className="input-parent">
                      <label className="customHeight">
                        Are you working with a SHAPE Partner, or have you been
                        referred by another company/person?
                        <br />
                        <span>
                          Knowing who you're working with lets us coordinate the
                          best experience for you.
                        </span>
                      </label>
                      <input
                        type="text"
                        id="pilot-input-distributor"
                        name="refferedBy"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="cta-pilot">
                  {submitted && (
                    <div className="submittedSuccess">
                      Thank you. We’ll be in touch very shortly.
                    </div>
                  )}
                  <p>
                    By submitting this questionnaire, I agree to the{" "}
                    <a id="pilot-terms-link" href="/pilotterms" target="_blank">
                      SHAPE Pilot Terms of Use
                    </a>
                  </p>
                  <button
                    id="pilot-form-submit-button"
                    type="submit"
                    disabled={submitted || submitting}
                    className="text-white"
                  >
                    {(submitted && "Submitted") ||
                      (submitting && "Submitting...") ||
                      "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    allCountryJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allPositionJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allIndustryJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allHowFindJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allCompanySizeJson {
      edges {
        node {
          value
          name
        }
      }
    }
  }
`
